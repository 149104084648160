import React, { useState, useEffect } from "react";
import WaitlistButton from "./waitlist_button.js";
import currency from "currency.js";

const WaitlistModal = ({
  show_modal,
  nextStep,
  camp_full,
  full_price_points,
  waitlist_price_point_ids,
}) => {
  const [cssStatusVariable, setCssStatusVariable] = useState("none");

  useEffect(() => {
    if (show_modal) {
      setCssStatusVariable("block");
    }
  }, [show_modal]);

  const hideModal = (e) => {
    e.preventDefault();
    nextStep();
  };

  const handleBackToHome = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };

  const renderCampMessage = () => {
    return (
      <div className="list-container">
        <ul className="full-pp-list">{buildFullPricePointList()}</ul>
      </div>
    );
  };

  const setbottomButton = () => {
    if (camp_full) {
      return (
        <button 
          className="continue-btn" 
          onClick={handleBackToHome}
          type="button"
        >
          Back to Homepage
        </button>
      );
    } else {
      return (
        <button 
          className="continue-btn" 
          onClick={hideModal} 
          type="submit"
        >
          Continue to Registration
        </button>
      );
    }
  };

  const setWaitlistText = () => {
    if (camp_full) {
      return "This event is full. Please click below to add yourself to the waitlist.";
    } else {
      return "This event is still open for registration but is full for the following registration types:";
    }
  };

  const buildFullPricePointList = () => {
    let results = [];
    results.push(
      <li className="waitlist-msg row" key={0}>
        {setWaitlistText()}
      </li>
    );

    for (let pp of full_price_points) {
      let priceString = currency(pp.price_cents, { fromCents: true });
      results.push(
        <li className="waitlist-item row" key={pp.id}>
          <span className="waitlist-title">
            {pp.title} <br /> 
            <p style={{ fontSize: '2em' }}>{priceString.format()}</p>
          </span>
          <WaitlistButton
            waitlist_price_point_ids={waitlist_price_point_ids}
            price_point_id={pp.id}
            onClick={() => window.location.href = "/"}
          />
        </li>
      );
    }
    return results;
  };

  return (
    <div className={`camp-status-modal-container ${cssStatusVariable}`}>
      <div className="heading">
        <h1 className="camp-status-modal-heading">
          Important Information:
        </h1>
      </div>
      <div className="modal-text">
        <span className="camp-msg">{renderCampMessage()}</span>
        <div className="modal-footer">{setbottomButton()}</div>
      </div>
    </div>
  );
};

export default WaitlistModal;