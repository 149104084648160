import React, { useState, useEffect } from "react";
import PositionDropdown from "./position_dropdown.js";
import HeightField from "./height_field.js";


const defaultPositions = {
  soccer: "Forward",
  basketball: "PG",
  lacrosse: "Attack",
  hockey: "Forward",
  volleyball: "Outside Hitter",
  baseball: "C",
  softball: "C",
  wrestling: "Wrestler",
  "soccer-advanced": "CM",
  "sports-performance": "other",
};

const TexasStateSportForm = (props) => {
  const setDefaultPosition = (sport) => {
    return defaultPositions[sport] || "All / Any";
  };
  const [state, setState] = useState({
      height: "",
      weight: "Not Collected",
      position: setDefaultPosition(props.sport),
      gpa: "",
      sat: "",
      act_score: "",
      club_team: "",
      notes: "",
      roommate_preference: "",
      peers_attending: "",
      graduation_year: "2022",
      shirt_size: "N/A",
      custom_data: {},
  });

  // useEffect(() => {
  //   setDefaultPosition(props.sport)
  // }, [props.sport])

  const heightChanged = (newHeight) => {
    setState(prevState => ({ ...prevState, height: newHeight }));
  };

  const valid = () => {
    return state.height;
  };

  const validateEmail = (email) => {
    const emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  const validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  const validateStreetAddress = (address) => {
    return console.log("validate");
  };

  const capitalizeString = (string) => {
    const capped = string.charAt(0).toUpperCase() + string.slice(1);
    return capped;
  };

  const addRoommatePreferenceField = () => {
    if (props.event_type == "traditional") {
      return (
        <div className="half">
          <label>Roommate Preferences:</label>
          <span className="error-span"></span>
          <textarea
            rows="5"
            name="roommate_preference"
            value={state.roommate_preference}
            onChange={handleChange}
            placeholder="Please list any roommate preferences..."
          ></textarea>
        </div>
      );
    }
  };

  const addIdCampFields = () => {
    if (props.event_type != "day-camp") {
      return (
        <div className="camper-info-row">
          <div className="half">
            <label>GPA:</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="gpa"
              placeholder="Enter GPA"
              name="gpa"
              value={state.gpa}
              data-error-message={
                "GPA field must not be blank - N/A is a valid response."
              }
              data-min-length={"2"}
              onChange={handleChange}
              onBlur={checkInput}
            />
          </div>
          <div className="half">
            <label>SAT Score</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="sat"
              placeholder="Enter SAT Score"
              name="sat"
              value={state.sat}
              data-error-message={
                "SAT field must not be blank - N/A is a valid response."
              }
              data-min-length={"2"}
              onChange={handleChange}
              onBlur={checkInput}
            />
          </div>
          <div className="half">
            <label>ACT Score:</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="sat"
              placeholder="Enter ACT Score"
              name="act_score"
              value={state.act_score}
              data-error-message={
                "ACT field must not be blank - N/A is a valid response."
              }
              data-min-length={"1"}
              onChange={handleChange}
              onBlur={checkInput}
            />
          </div>
          <div className="half">
            <p>Graduation Years</p>
            <select
              onChange={handleChange}
              name={"graduation_year"}
              className={"grad-year field"}
              data-name={"graduation-year"}
              required
              defaultValue=""
            >
              <option value="" disabled>Select Grad Year</option>
              {setUpGradYearOptions()}
            </select>
          </div>
        </div>
      );
    }
  };

  const setUpGradYearOptions = () => {
    const gradYears = Array.from({ length: 14 }, (_, i) => i + 2019).concat("N/A");

    return gradYears.map((gradYear) => (
      <option value={gradYear} key={gradYear}>
        {gradYear}
      </option>
    ));
  };

  const setUpShirtSizeOptions = () => {
    const shirtSizes = [
      "N/A",
      "Y-Small",
      "Y-Medium",
      "Y-Large",
      "Small",
      "Medium",
      "Large",
      "X-Large",
    ];

    return shirtSizes.map((shirtSize) => (
      <option value={shirtSize} key={shirtSize}>
        {shirtSize}
      </option>
    ));
  };

  const addShirtField = () => {
    if (props.collect_tee_shirt_size) {
      return (
        <div className="camper-info-row">
          <div className="half">
            <p>Shirt Size (if applicable): *</p>
            <span className="error-span"></span>
            <select
              onChange={handleChange}
              name="shirt_size"
              className="shirt-size field"
              data-name="shirt_size"
            >
              {setUpShirtSizeOptions()}
            </select>
          </div>
        </div>
      );
    }
  };

  const checkInput = (e) => {
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(item, errorSpan, val.toString().length, item.dataset.minLength);
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      errorSpan.innerHTML = errorMessage;
    } else {
      item.classList.remove("field-error");
      errorSpan.innerHTML = "";
    }
  };

  const positionChange = (positionValue) => {
    setState((prevState) => ({ ...prevState, position: positionValue }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const saveAndContinue = (e) => {
    e.preventDefault();

    const data = {
      height: state.height,
      weight: state.weight + "lbs",
      position: state.position,
      club_team: state.club_team,
      gpa: state.gpa,
      sat: state.sat,
      act_score: state.act_score,
      graduation_year: state.graduation_year,
      roommate_preference: state.roommate_preference,
      peers_attending: state.peers_attending,
      shirt_size: state.shirt_size,
    };
    props.saveValues(data);
    return props.nextStep();
  };
  return (
    <form className="new-step-form">
      <h1 className="form-heading">
        {"Step 3. " + capitalizeString(props.sport) + " Information"}
      </h1>
      <div className="camper-info-row">
        <div className="half">
          <span className="error-span"></span>
          <label className="h">{"Position: *"}</label>
          <PositionDropdown
            onChange={positionChange}
            value={state.position}
            sport={props.sport}
            full_price_points={props.full_position_price_points}
          />
        </div>
        <div className="half">
          <span className="error-span"></span>
          <label>{"Club Team: *"}</label>
          <input
            type="text"
            className="club-team full-field"
            placeholder="Club Team"
            name="club_team"
            data-error-message={
              "Club Team must be at least one character - N/A is a valid response."
            }
            data-min-length="1"
            value={state.club_team}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
      </div>
      <div className="camper-info-row">
        <span className="error-span"></span>
        <p>{"Height: *"}</p>
        <HeightField
          name="height"
          feet={1}
          inches={0}
          value={state.height}
          onChange={heightChanged}
        />
      </div>
      <div className="camper-info-row">
        <div className="half">
          <span className="error-span"></span>
          <textarea
            rows={{ 5: 5 }}
            className="allergies full-field"
            placeholder="Please list any peers attending...."
            name="peers_attending"
            onChange={handleChange}
            onBlur={checkInput}
            value={state.peers_attending}
          ></textarea>
        </div>
        {addRoommatePreferenceField()}
      </div>
      <div className="camper-info-row">{addIdCampFields()}</div>
      <div className="camper-info-row">{addShirtField()}</div>
      <div className="camper-info-row">
        <button
          type="submit"
          className="button button-primary create"
          disabled={!valid()}
          style={props.buttonStyles}
          onClick={saveAndContinue}
        >
          {"Next Step"}
        </button>
      </div>
    </form>
  );
};

export default TexasStateSportForm;