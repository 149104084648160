import React, { useState, useEffect } from "react";
import BirthDateSelect from "./form_fields/birth_date_select";
import Checkbox from './form_fields/checkbox_input.js';
import Cleave from "cleave.js/react";
import CountryCodeSelect from "./form_fields/country_code_select.js";
import DateDropdown from "./form_fields/date_dropdown.js";
import EmailInput from "./form_fields/email_input.js";
import EmergencyContactInput from "./form_fields/emergency_contact_input.js";
import EmergencyContactNumberInput from "./form_fields/emergency_contact_number_input.js";
import GradeInput from "./form_fields/grade_input.js";
import GradePointAverageField from "./form_fields/grade_point_average_field.js";
import GradYearSelect from "./form_fields/grad_year_select.js";
import HeightInput from "./form_fields/height_input.js";
import PhysicianContactNumberInput from "./form_fields/physician_contact_number_input.js";
import PhysicianInfoForm from "./form_fields/physician_info_form.js";
import PositionSelect from "./form_fields/position_select.js";
import SatScoreField from "./form_fields/sat_score_field.js";
import ActScoreField from "./form_fields/act_score_field.js";
import StateSelectField from "./form_fields/state_select_field.js";
import TshirtField from "./form_fields/tshirt_field.js";
import TextArea from './form_fields/text_area_input.js';
import TextField from "./form_fields/text_field.js";
import TextInput from './form_fields/text_input.js';
import WeightInput from "./form_fields/weight_input.js";
import AgeGroupSelect from "./form_fields/age_group_select.js";
import TeammatesCollectionField from "./form_fields/teammates_collection_field.js";

// need to add:
// import GenderField from "./form_fields/gender_input.js";

const CustomInfoForm = ({
  collectGuardianInfo,
  existingCamperId,
  existingAttendeeData,
  campers,
  emergencyContact,
  sport,
  event_type,
  full_position_price_points,
  price_points,
  data_points,
  category,
  saveValues,
  nextStep,
  buttonStyles,
}) => {
  const [formData, setFormData] = useState({
    showModal: null,
    first_name: "",
    last_name: "",
    birth_date: new Date("2006-01-01"),
    playerPhoneNumber: "",
    emergencyContact: "",
    emergencyContactCountryCode: "+1",
    emergencyContactNumber: "",
    gender: "Male",
    grade: "",
    guardianContact: "",
    guardianContactCountryCode: "+1",
    guardianContactNumber: "",
    guardianContactEmail: "",
    guardianContactRelationship: "",
    email: "",
    address: "",
    city: "",
    state: "AL - Alabama",
    zip: "",
    price_points: {},
    physician: "",
    physician_contact_number: "",
    insurer: "",
    group_number: "",
    medications: "",
    conditions: "",
    allergies: "",
    diabetes: false,
    asthma: false,
    height: "",
    weight: "",
    position: "",
    gpa: "",
    sat: "",
    act_score: "",
    club_team: "",
    user_notes: "",
    roommate_preference: "",
    peers_attending: "",
    graduation_year: "",
    shirt_size: "N/A",
    custom_data: {},
  });

  useEffect(() => {
    // console.log(data_points); solved by setting datatemplate id on Camp
    if (existingAttendeeData) {
        setFormData((prevState) => ({
        ...prevState,
        showModal: null,
        first_name: existingAttendeeData.first_name || "",
        last_name: existingAttendeeData.last_name || "",
        birth_date: existingAttendeeData.birth_date || new Date("2006-01-01"),
        player_phone_number: existingAttendeeData.player_phone_number || "",
        emergency_contact: existingAttendeeData.emergency_contact || "",
        emergencyContactCountryCode: existingAttendeeData.emergency_contact_country_code || "+1",
        emergency_contact_number: existingAttendeeData.emergency_contact_number || "",
        gender: existingAttendeeData.gender || "",
        grade: existingAttendeeData.grade || "",
        email: existingAttendeeData.email || "",
        address: existingAttendeeData.address || "",
        city: existingAttendeeData.city || "",
        state: existingAttendeeData.state || "AL - Alabama",
        zip: existingAttendeeData.zip || "",
        price_points: existingAttendeeData.price_points || {},
        physician: existingAttendeeData.physician || "",
        physician_contact_number: existingAttendeeData.physician_contact_number || "",
        insurer: existingAttendeeData.insurer || "",
        group_number: existingAttendeeData.group_number || "",
        medications: existingAttendeeData.medications || "",
        conditions: existingAttendeeData.conditions || "",
        allergies: existingAttendeeData.allergies || "",
        diabetes: existingAttendeeData.diabetes === true,
        asthma: existingAttendeeData.asthma === true,
        height: existingAttendeeData.height || "",
        weight: existingAttendeeData.weight || "",
        position: existingAttendeeData.position || "",
        gpa: existingAttendeeData.gpa || "",
        sat: existingAttendeeData.sat || "",
        act_score: existingAttendeeData.act_score || "",
        club_team: existingAttendeeData.club_team || "",
        notes: existingAttendeeData.custom_data?.notes || "N/A",
        user_notes: existingAttendeeData.custom_data?.user_notes || "N/A",
        roommate_preference: existingAttendeeData.roommate_preference || "",
        peers_attending: existingAttendeeData.peers_attending || "",
        graduation_year: existingAttendeeData.custom_data?.graduation_year || "2022",
        shirt_size: existingAttendeeData.custom_data?.shirt_size || "N/A",
        custom_data: existingAttendeeData.custom_data || {},
      }));
    }
  }, [existingAttendeeData]);

  const positionChange = (positionValue) => {
    const fieldName = "position";
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: positionValue
    }));
  };

  const handleChange = (e) => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    console.log(`Changing field ${name} to ${value}`);
    // If the field exists within custom_data
    if (name in formData.custom_data) {
      setFormData(prevState => ({
        ...prevState,
        custom_data: {
          ...prevState.custom_data,
          [name]: value
        }
      }));
    } else {
      // For top-level state properties
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const formatFieldName = (fieldName) => {
    return fieldName.replace(/(^|_)\w/g, match => match.replace('_', '').toUpperCase());
  };

  const propsForField = (datapoint) => {
      switch (datapoint.field_name) {
      case 'state':
          return {
              key: datapoint.id,
              label: (datapoint.field_label || formatFieldName(datapoint.field_name)),
              name: datapoint.field_name,
              value: formData[datapoint.field_name] || '',
              state: formData.state || "AL - Alabama",
              required: datapoint.required,
              onChange: stateDropdownChanged,
          };
        case 'birth_date':
          return {
              key: datapoint.id,
              label: (datapoint.field_label || formatFieldName(datapoint.field_name)),
              name: datapoint.field_name,
              value: formData[datapoint.field_name] || '',
              formDataBirthdate: formData[datapoint.field_name] || '',
              required: datapoint.required,
              onChange: handleChange,
          };
        case 'position':  
          return {
              key: datapoint.id,
              label: (datapoint.field_label || formatFieldName(datapoint.field_name)),
              name: datapoint.field_name,
              value: formData[datapoint.field_name] || '',
              required: datapoint.required,
              onChange: positionChange,
          };
        case 'height':
          return {
              key: datapoint.id,
              label: (datapoint.field_label || formatFieldName(datapoint.field_name)),
              name: datapoint.field_name,
              value: formData[datapoint.field_name] || '',
              formDataHeight: formData[datapoint.field_name] || '',
              required: datapoint.required,
              onChange: handleChange,
          };
          case 'club_team':
            return {
                key: datapoint.id,
                label: (datapoint.field_label || formatFieldName(datapoint.field_name)),
                name: datapoint.field_name,
                value: formData[datapoint.field_name] || '',
                placeholder: "Club Team",
                required: datapoint.required,
                onChange: handleChange,
            };
          case 'asthma':
          case 'diabetes':
          case 'boolean':
          case 'checkbox':
            return {
                key: datapoint.id,
                label: (datapoint.field_label || formatFieldName(datapoint.field_name)),
                name: datapoint.field_name,
                checked: formData[datapoint.field_name] || '',
                value: formData[datapoint.field_name] || '',
                placeholder: (datapoint.field_label || formatFieldName(datapoint.field_name)),
                required: datapoint.required,
                onChange: handleChange,
            };
        default:
          return {
              key: datapoint.id,
              label: (datapoint.field_label || formatFieldName(datapoint.field_name)),
              name: datapoint.field_name,
              value: formData[datapoint.field_name] || '',
              required: datapoint.required,
              onChange: handleChange,
          };

    }
  };

  const renderField = (datapoint) => {
    const fieldProps = propsForField(datapoint);
    switch (datapoint.field_type) {
      case 'string':
        return <TextField {...fieldProps} />;
      case 'email':
        return <EmailInput {...fieldProps} />;
      case 'height':
        return <HeightInput {...fieldProps} />;
      case 'grade':
        return <GradeInput {...fieldProps} />;
      case 'years_of_training':
        return <YearsOfTrainingField {...fieldProps} />
      case 'years_in_sport':
        return <YearsInSportField {...fieldProps} />
      case 'gpa':
        return <GradePointAverageField {...fieldProps} />;
      case 'act':
        return <ActScoreField {...fieldProps} />;
      case 'sat':
        return <SatScoreField {...fieldProps} />;
      case 'weight':
        return <WeightInput {...fieldProps} />;
      case 'shirt_select':
        return <TshirtField {...fieldProps} />;
      case 'age_group_select':
        return <AgeGroupSelect {...fieldProps} />;
      case 'graduation_year':
      case  'grad_year':
        return <GradYearSelect {...fieldProps} />;
      case 'position':
        return <PositionSelect {...fieldProps} position={formData.position || ""} sport={sport} price_points={price_points} full_position_price_points={full_position_price_points} />;
      case 'state':
        return <StateSelectField {...fieldProps} />
      case 'group_number':
        return <TextInput {...fieldProps} />
      case 'text_input':
      case 'club_team':
          return <TextInput {...fieldProps} />;
      case 'text_area':
        return <TextArea {...fieldProps} />;
      case 'medications':
      case 'conditions':
      case 'allergies':
      case 'roommate_preference':
      case 'peers_attending':
      case 'notes':
      case 'text':
        return <TextArea {...fieldProps} />;
      case 'asthma':
      case 'diabetes':
      case 'boolean':
      case 'checkbox':
        return <Checkbox {...fieldProps} />;
      case 'emergency_contact':
        return <EmergencyContactInput {...fieldProps} />;
      case 'emergency_contact_number':
        return (
            <EmergencyContactNumberInput 
              key={datapoint.id}
              formData={formData}
              formDataPhoneNumber={formData.emergency_contact_number}
              countryCode={formData.emergencyContactCountryCode}
              countryCodeDropdownChanged={countryCodeDropdownChanged}
              handlePhoneChange={handlePhoneChange}
              checkInput={checkInput} 
            />
          );
      case 'physician':
        return <PhysicianInfoForm 
                    key={datapoint.id}
                    state={formData}
                    name={datapoint.field_name}
                    handleChange={handleChange} 
                    checkInput={checkInput}
                  />;
      case 'physician_contact_number':
        return <PhysicianContactNumberInput 
                    key={datapoint.id}
                    name={datapoint.field_name}
                    state={formData}
                    handleChange={handleChange}
                    checkInput={checkInput}
                  />;
      case 'date':
        return (
          <DateDropdown
            label={datapoint.label}
            name={datapoint.field_name}
            value={formData[datapoint.field_name] || new Date()}
            onChange={(newValue) => handleDateChange(datapoint.field_name, newValue)}
            key={datapoint.id}
          />
        );
      case 'birth_date':
        return (
          <BirthDateSelect
            birth_date={formData.birth_date}
            name={"birth_date"}
            value={formData[datapoint.field_name] || new Date()}
            onChange={dateChanged}
            key={datapoint.id}
          />
        );
      default:
        return (
          <div key={datapoint.id}>
            Unsupported field type "{datapoint.field_type}" for field "{datapoint.field_name}"
          </div>
        );
    }
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    let countryCode =
      name === "guardianContactNumber"
        ? formData.guardianContactCountryCode
        : formData.emergencyContactCountryCode;

    setFormData((prevState) => ({
      ...prevState,
      [name]: countryCode + " " + value,
    }));
  };

  const dateChanged = (newDate) => {
    setFormData((prevState) => ({ ...prevState, birth_date: newDate }));
  };

  const genderFieldChanged = (selectedGender) => {
    setFormData((prevState) => ({ ...prevState, gender: selectedGender }));
  };

  const stateDropdownChanged = (newState) => {
    // console.log("Updating state to:", newState);
    setFormData((prevState) => ({ ...prevState, state: newState }));
  };

  const countryCodeDropdownChanged = (newCode) => {
    setFormData((prevState) => ({
      ...prevState,
      emergencyContactCountryCode: newCode,
    }));
  };

  const guardianCountryCodeDropdownChanged = (newCode) => {
    setFormData((prevState) => ({
      ...prevState,
      guardianContactCountryCode: newCode,
    }));
  };

  const valid = () => {
    return true
  }

  const validateLength = (field, requiredLength) => {
    if (field && field.length > requiredLength) {
      return true;
    }
  };

  const validateEmail = (email) => {
    var emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  const checkInput = (e) => {
    let itemClass = e.target.className;
    let item = e.target;
    let val = e.target.value;
    let errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const setGuardianFields = () => {
    const { collect_guardian_info, emergency_contact } = formData;

    if (collect_guardian_info) {
      return (
        <div className={"camper-info-row"}>
          <span className={"error-span"}></span>
          <label className={"big-span"}>
            {"Guardian Contact Information: *"}
          </label>
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Guardian Name"}
            data-error-message={
              "Guardian Contact Name must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact"}
            minLength={5}
            defaultValue={formData.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Guardian Relationship - Mother, Father, etc"}
            data-error-message={
              "Guardian Relationship must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact_relationship"}
            minLength={2}
            defaultValue={formData.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <div className={"half"}>
            <CountryCodeSelect
              value={formData.guardian_contact_country_code}
              onChange={guardianCountryCodeDropdownChanged}
            />
            <Cleave
              type={"tel"}
              id={""}
              className={"ecn phone-us field two-thirds-field"}
              size={10}
              pattern={"[0-9]{10}"}
              id={""}
              name={"guardian_contact_number"}
              placeholder={"Guardian Contact #"}
              data-error-message={
                "Guardian Phone must be 10 characters. If you are still having issues, clear the field and try again."
              }
              data-min-length={"10"}
              options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
              onChange={handlePhoneChange}
              onBlur={checkInput}
            />
          </div>
          <input
            type={"email"}
            className={"half full-field"}
            placeholder={"Guardian Email"}
            data-error-message={
              "Guardian Relationship must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact_email"}
            minLength={5}
            required={true}
            defaultValue={formData.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
      );
    }
  };

  // Check if the camp is full
  const campFull = () => {
    return formData.price_points.length === createFullPricePointArray().length;
  };

  // Check if a price point is full
  const setFullPricePointFlag = () => {
    const pricePoints = formData.price_points;
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count >= pricePoints[i].capacity) {
        return true;
      }
    }
    return false;
  };

  // Create an array of open price points
  const createOpenPricePointArray = () => {
    const pricePoints = formData.price_points;
    let openPricePoints = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count <= pricePoints[i].capacity) {
        openPricePoints.push(pricePoints[i]);
      }
    }
    return openPricePoints;
  };

  // Create an array of full price points
  const createFullPricePointArray = () => {
    const pricePoints = formData.price_points;
    let fullPricePoints = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count >= pricePoints[i].capacity) {
        fullPricePoints.push(pricePoints[i]);
      }
    }
    return fullPricePoints;
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      var errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

 const saveAndContinue = (e) => {
   e.preventDefault();

   const data = {
     // Grad Year is not updating in custom reg flo

     // Basic Information
     first_name: formData.first_name,
     last_name: formData.last_name,
     birth_date: formData.birth_date,
     gender: formData.gender,
     email: formData.email,
     player_phone_number: formData.player_phone_number,
     
     // Address
     address: formData.address,
     city: formData.city,
     state: formData.state,
     zip: formData.zip,
     
     // Medical Information
     physician: formData.physician,
     physician_contact_number: formData.physician_contact_number,
     physician_city: formData.physician_city,
     insurer: formData.insurer,
     insurer_phone_number: formData.insurer_phone_number,
     insurance_policy_holder: formData.insurance_policy_holder,
     group_number: formData.group_number,
     medications: formData.medications,
     conditions: formData.conditions,
     allergies: formData.allergies,
     diabetes: formData.diabetes,
     asthma: formData.asthma,
     sickle_cell_positive: formData.sickle_cell_positive,
     glasses_or_contacts: formData.glasses_or_contacts,
     special_needs: formData.special_needs,
     
     // Emergency Contact
     emergency_contact: formData.emergency_contact,
     emergency_contact_number: formData.emergency_contact_number,
     
     // School and Sports Information
     height: formData.height,
     weight: formData.weight,
     position: formData.position,
     grade: formData.grade,
     gpa: formData.gpa,
     sat: formData.sat,
     act_score: formData.act_score,
     club_team: formData.club_team,
     high_school: formData.high_school,
     
     // Preferences and History
     roommate_preference: formData.roommate_preference,
     peers_attending: formData.peers_attending,
     dietary_restrictions: formData.dietary_restrictions,
     physical_restrictions: formData.physical_restrictions,
     injury_history: formData.injury_history,
     training_experience: formData.training_experience,
     goals: formData.goals,
     sports: formData.sports,
     years_in_sport: formData.years_in_sport,
     years_of_training: formData.years_of_training,
     
     // Covid Information
     vaccinated: formData.vaccinated,
     vaccine_type: formData.vaccine_type,
     vaccine_dose_one_date: formData.vaccine_dose_one_date,
     vaccine_dose_two_date: formData.vaccine_dose_two_date,
     positive_test_date: formData.positive_test_date,
     covid_positive: formData.covid_positive,
     covid_cleared: formData.covid_cleared,
     
     // Miscellaneous
     shirt_size: formData.shirt_size,
     graduation_year: formData.graduation_year,
     liability_waiver: formData.liability_waiver,
     liability_waiver_initials: formData.liability_waiver_initials,
     liability_waiver_signature: formData.liability_waiver_signature,
     guardian_contact: formData.guardian_contact,
     guardian_contact_number: formData.guardian_contact_number,
     guardian_contact_relationship: formData.guardian_contact_relationship,
     guardian_contact_email: formData.guardian_contact_email,
     
     // Social Media
     tik_tok_profile_url: formData.tik_tok_profile_url,
     instagram_profile_url: formData.instagram_profile_url,
     hudl_profile_url: formData.hudl_profile_url,
     facebook_profile_url: formData.facebook_profile_url,
     youtube_profile_url: formData.youtube_profile_url,

     // Additional Data
     custom_data: formData.custom_data || {},
   };

   saveValues(data);
   return nextStep();
 };

  return (
    <form className={"new-step-form"} autoComplete={"off"}>
      <div className={"buck-test"}>
        <h1 className={"form-heading"}>{"Step 1. Registration Information"}</h1>
      </div>
      {data_points.map((datapoint) => renderField(datapoint))}
      <button
        type={"submit"}
        className={"button button-primary create"}
        style={buttonStyles}
        disabled={!valid()}
        onClick={saveAndContinue}
      >
        {"Next Step"}
      </button>
    </form>
  );
};

export default CustomInfoForm;
