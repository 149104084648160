import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["campSelect", "pricePointSelect"]
  
  connect() {
    console.log('SwitchCamp controller connected');
    // Initialize price point select with default option
    this.resetPricePointSelect();
  }

  updatePricePoints() {
    const campId = this.campSelectTarget.value;
    
    if (!campId) {
      this.resetPricePointSelect();
      return;
    }

    fetch(`/admin/camps/${campId}/price_points`)
      .then(response => response.json())
      .then(data => this.populatePricePoints(data))
      .catch(error => {
        console.error('Error fetching price points:', error);
        this.resetPricePointSelect();
      });
  }

  populatePricePoints(pricePoints) {
    const defaultOption = '<option value="" disabled selected>Select Registration Type</option>';
    const options = pricePoints.map(pp => `<option value="${pp.id}">${pp.title}</option>`).join("");
    this.pricePointSelectTarget.innerHTML = defaultOption + options;
  }

  resetPricePointSelect() {
    this.pricePointSelectTarget.innerHTML = '<option value="" disabled selected>Select Registration Type</option>';
  }
}